import React, { useEffect, useState, useContext } from "react";
import { Context } from "../Context";
import styled from "styled-components";
import Container from "./Container";
import Spin from "../Utilities/SpinnerLoader";
import img_exp from "../assets/images/imagen_experencia.png";
import SDK from "../sdk/DekalbSDK";
import Carousel from "../components/Carousel";

const StyledExperience = styled.section`
  padding: 4rem 0px;
  padding-bottom: 30px;
  h1 {
    text-align: center;
  }

  .select {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    select {
      width: 256px;
      height: 33px;
      background: #ffd22b;
      border-radius: 4px;

      font-weight: bold;
      text-align: center;
      font-size: 14px;
    }
  }
  .spin {
    min-height: 400px;
    margin-top: 30px;
  }
  .info {
    margin: auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .name {
      width: 100%;
      max-width: 400px;

      background: white;

      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        margin: 0;
        font-family: DIN_Italic_Med;
        font-size: 50px;
        color: red;
        display: flex;

        span {
          font-size: 20px;
        }
      }
    }

    .producto_ {
      width: 100%;
      max-width: 400px;
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      h1 {
        display: flex;
        margin: 0;
        font-size: 24px;
        margin-bottom: 20px;
        span {
          font-size: 15px;
          margin-left: 3px;
          margin-top: -3px;
          font-family: DIN_Italic_Bold !important;
        }
      }

      p {
        margin-bottom: 20px;
        span {
          font-family: DIN_Italic_Bold !important;
          font-size: 1.8rem !important;
        }
      }

      .comentarios {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (min-width: 992px) {
    .info {
      .name {
        margin-right: 50px;
      }
    }
  }
`;

const Experiencias = (props) => {
  const [context] = useContext(Context);
  const [state, setstate] = useState({});

  useEffect(() => {
    getComents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  async function getComents() {
    if (context.productos && context.productos.length > 0) {
      const primerProducto = context.productos[0];
      setstate({
        ...state,
        fetch: true,
      });
      const Comentarios = await SDK.getComentarios(
        props.state,
        primerProducto.id
      );

      console.log(Comentarios.Informacion.Rendimiento[0]);

      if(Comentarios.Informacion.Rendimiento[0] !== undefined) {
        context.productos[0].Rendimiento = Comentarios.Informacion.Rendimiento[0].Rendimiento;
        context.productos[0].Hectareas = Comentarios.Informacion.Rendimiento[0].Hectareas;
      }

      setstate({
        ...state,
        Comentarios: Comentarios.Informacion.Comentarios,
        producto: context.productos[0],
        productos: context.productos,
        fetch: false,
      });
    }
  }

  async function OnChangeProducto(element) {
    const producto = state.productos.find(
      (e) => e.id === parseInt(element.target.value)
    );

    setstate({
      ...state,
      fetch: true,
    });
    const Comentarios = await SDK.getComentarios(props.state, producto.id);
    producto.Rendimiento = Comentarios.Informacion.Rendimiento[0].Rendimiento
    producto.Hectareas = Comentarios.Informacion.Rendimiento[0].Hectareas
    setstate({
      ...state,
      Comentarios: Comentarios.Informacion.Comentarios,
      producto: producto,
      fetch: false,
    });
  }

  function getImage(picture) {
      try {
        return require(`../assets/images/products/${picture.replace(
          " ",
          ""
        )
          .replace("-", "")
          .replace("®", "")
          .trim()
          .toLowerCase()}.jpg`)
      } catch(e) {
        console.log(e)
        return require(`../assets/images/imagen_experencia.png`)
      }
  }

  return state.productos && context.productos.length > 0 ? (
    <StyledExperience>
      <Container>
        <h1>Experiencia por híbrido según agricultores</h1>
        <div className="select">
          <select
            name="producto"
            id="producto"
            disabled={state.productos.length === 0 ? true : false}
            value={state.producto.id}
            onChange={(e) => OnChangeProducto(e)}
          >
            {state.productos.map((ele, idx) => {
              return (
                <option key={ele.id} value={ele.id}>
                  {ele.Nombre}
                </option>
              );
            })}
          </select>
        </div>
        {!state.fetch ? (
          <div className="info">
            <div className="imagen">
              {state.producto ? (
                <div className="name">
                  {/* <h2>
                    {state.producto.Nombre}
                    <span>&copy;</span>
                  </h2> */}
                  {<img
                    src={getImage(state.producto.Nombre)}
                    style={{ width: "100%", padding: "16px" }}
                    alt=" "
                    onError={(event) => event.target.src = ''}
                    />}
                </div>
              ) : (
                <img src={img_exp} alt="" />
              )}
            </div>
            <div className="producto_">
              <h1>
                
                {state.producto.Nombre} <span>&copy;</span>
              </h1>
              <p>
                Hectáreas sembradas registradas:{" "}
                <span>{state.producto.Hectareas}</span>
              </p>
              <p>
                Rendimiento: <span>{state.producto.Rendimiento}</span>
              </p>
              {state.Comentarios ? (
                <div className="comentarios">
                  <h1>Comentarios</h1>
                  <Carousel comentarios={state.Comentarios} />
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <Spin />
        )}
      </Container>
    </StyledExperience>
  ) : null;
};

export default Experiencias;
