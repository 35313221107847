import React, { useContext } from "react";
import { Context } from "../Context";
import styled from "styled-components";
import Container from "./Container";
import dk_1N from "../assets/images/nuestrosproductos.png";

const StyledNuestrosProductos = styled.section`
  padding: 4rem 0px;

  .img-holder {
    position: relative;
    display: inline-block;
  }
  .img-holder img.imgBackgrund {
    display: block;
  }
  .img-holder .texto {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    text-align: center;
    color: #fff;
  }

  .img-holder h4 {
    font-style: italic;
    font-weight: 900;
    font-size: 48px;
  }

  .texto-resultados-dekalb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .texto-resultados-dekalb div {
    height: 100%;
    margin: 30px 0px;
  }
  .producto {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
`;

export default function NuestrosProductos() {
  const [context] = useContext(Context);
  return (
    <StyledNuestrosProductos>
      <div className="texto-resultados-dekalb">
        <h1>NUESTROS PRODUCTOS</h1>
        <p>Haz click en el producto para obtener mayor información.</p>
      </div>
      <br />
        <Container>
          {/* PRODUCTOS PACÍFICO */}
          <div className="row">
            <div className="col-12 offset-lg-1 col-lg-10  ">
              <div className="row">
                <div className="col-12 col-lg-12 producto">
                  <div className="img-holder">
                    <a href="https://www.dekalb.com.mx/es-mx/productos-dekalb-/maiz.html" target="_blank">
                      <img style={{ width: "100%" }} src={dk_1N} alt="DEKALB" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </Container>
    </StyledNuestrosProductos>
  );
}
